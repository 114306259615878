<template>
    <div class="container_goods">
        <clone-tab :bgcShow="bgcShow"></clone-tab>

        <!-- 詳情 -->
        <div>
            <div class="goods_img">
              <img :src="details.goods_image"/>
            </div>
            <div class="goods_title">{{details.goods_name}}</div>
            <div class="price_item" v-if="userFrom.union_member_freeze==0">
                <div style="text-decoration:line-through">¥{{details.goods_price}}</div>
                <div v-if="details.goods_sale_range==1" class="share_item">会员福利 ¥ {{details.goods_discount_price}}</div>
                <div v-else class="share_item">股东福利 ¥ {{details.goods_discount_price}}</div>
            </div>
            <div class="price_item" v-else>
              <div>¥{{details.goods_price}}</div>
              
            </div>
            <div class="quantity">
                <div>
                    <div>快递</div>
                    <div class="quantity_p">{{details.goods_freight!=0?details.goods_freight:'免费'}}</div>
                </div>
                <div>
                    <div>库存</div>
                    <div class="number">{{details.goods_storage}}</div>
                </div>
                <div>
                    <div>已销售</div>
                    <div class="number">{{details.goods_sales}}</div>
                </div>
            </div>

            <div class="quantity">
                <div class="details">
                    <div>商品详情</div>
                </div>
            </div>
            <div class="details_list" v-html="details.goods_body"></div>
        </div>
        <div class="confimBuy" @click="confimBuy">立即购买</div>
    </div>
</template>
<script>
import cloneTab from "../../components/expenditure/cloneTab";
import {goodsDetails_api,} from "@/api/deal";
import { getuser_api } from "@/api/common";
export default {
  data() {
    return {
      bgcShow: true,
      details:null,
      userFrom:{}
    };
  },
  components: {
    cloneTab
  },
  created() {
    document.title = "限时专区";
    this.getDetails(this.$route.query.id);
    this.getUser()
  },
  methods: {
    getUser() {
      getuser_api().then(res => {
        console.log(res, "res");
        if (res.code == 0) {
          this.userFrom = res.data;
          console.log(this.userFrom.union_expire_at)
          if(new Date().getTime()>new Date(this.userFrom.union_expire_at).getTime()){
            this.userFrom.vipUser = 1
          }else{
             this.userFrom.vipUser = 0
          }
          if(new Date().getTime()>new Date(this.userFrom.union_shareholder_expire_at).getTime()){
            this.userFrom.vipShare = 1
          }else{
            this.userFrom.vipShare = 0
          }
          console.log(this.userFrom)
        }
      });
    },
    getDetails(id){
      goodsDetails_api(id).then(res=>{
        console.log(res);
        if(res.code==0){
          this.details = res.data
        }
      })
    },
    confimBuy(){
      this.$router.push(`./confimOrder?id=${this.details.goods_id}`)
    }
  }
};
</script>
<style scoped>
.container_goods {
  padding-top: 20px;
}
.details_list{
  padding: 20px 50px;
  box-sizing: border-box;
}
.goods_img {
  width: 750px;
  height: 675px;
  opacity: 1;
  border-radius: 0px;
  background-color: pink;
  margin: 20px auto;
}
.goods_img img{
  width:100%;
  height:100%
}
.goods_title {
  font-size: 40px;
  color: #545353;
  padding: 20px 92px 20px 18px;
  box-sizing: border-box;
  font-weight: bold;
}
.price_item {
  margin-left: 18px;
  display: flex;
  font-size: 50px;
  color: #ff4400;
  margin-top: 20px;
  padding-bottom: 20px;
}
.share_item {
  width: 289px;
  height: 56px;
  background: #ff4400;
  opacity: 1;
  border-radius: 28px;
  text-align: center;
  line-height: 56px;
  font-size: 30px;
  color: #fff;
  margin-left: 30px;
}
.quantity {
  display: flex;
  justify-content: space-between;
  width: 750px;
  height: 100px;
  background: #ffffff;
  opacity: 1;
  border-radius: 0px;
  padding: 20px 52px;
  box-sizing: border-box;
  border-top: 20px solid #f2f2f2;
  font-size: 30px;
}

.quantity > div {
  display: flex;

  font-size: 30px;
  color: rgba(0, 0, 0, 0.35);
}
.quantity .number {
  color: #ff4400;
  font-size: 25px;
  margin-left: 20px;
  line-height: 40px;
}

.quantity .details {
  display: flex;
  justify-content: space-between;
  width:100%
}
.quantity_p {
  color: rgba(84, 83, 83, 1);
  margin-left: 20px;
}
.confimBuy {
  width: 212px;
  height: 68px;
  background: #ff4400;
  border: 1px solid #f26016;
  opacity: 1;
  border-radius: 2px;
  text-align: center;
  line-height:68px;
  font-size: 29px;
  color:#fff;
  position:fixed;
  bottom: 20px ;
  right:40px
}
.quantity_b{
    color: rgba(84, 83, 83, 1);
}
</style>
